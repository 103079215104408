@import '../_typography';
@import '../media-queries';
@import '../_variables';

.sidebar {
  width: 270px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: margin-right 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-right: -270px;
  position: relative;
  z-index: 1000;

  @include only-phone {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
  }

  &.show {
    margin-right: 0;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    margin-bottom: 20px;

    .sidebar-logo img {
      height: 24px;
      width: auto;
    }
  }

  .sidebar-top {
    flex: 1;
    overflow-y: auto;
    direction: ltr;
  }

  .sidebar-bottom {
    margin-top: 50px;
    direction: ltr;

    .sidebar-account {
      direction: rtl;
      display: flex;
      align-items: center;
      padding: 24px;
      border-top: 1px solid #f1f1f1;
      margin-top: 8px;

      .sidebar-account-img {
        margin-left: 15px;
      }

      .sidebar-account-name {
        @extend .tg-body-lg;
        color: #4f5b67;
      }

      .sidebar-account-desc {
        @extend .tg-body-sm;
        color: #4f5b67;
      }
    }
  }

  .sidebar-nav {
    direction: rtl;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 8px;

      a {
        padding: 12px 24px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;

        .nav-icon {
          font-size: 18px;
          margin-left: 23px;
        }

        .nav-text {
          @extend .tg-body-lg;
          font-size: 14px;
        }

        &:hover {
          background-color: #f7f7fb;
        }

        &.active {
          background-color: #f7f7fb;
          border-left: 4px solid $primary-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.sidebar.dark {
  background-color: #000000;
  color: #f1f1f1;

  .sidebar-nav {
    li a {
      &:hover {
        background-color: #242d35;
      }

      &.active {
        background-color: #242d35;
      }
    }
  }

  .sidebar-bottom {
    margin-top: 50px;

    .sidebar-account {
      .sidebar-account-name {
        color: #f1f1f1;
      }

      .sidebar-account-desc {
        color: #f1f1f1;
      }
    }
  }
}

.sidebar-backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;

  @include only-phone {
    display: block;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}
