.sd-upload-loading {
  display: flex;
  align-items: center;

  .sd-loader-container {
    flex: 1;
    height: 30px;
    background-color: #e5f0ff;
    border-radius: 15px;
    overflow: hidden;
    position: relative;

    .sd-loader-indicator {
      height: 100%;
      background-color: #9dbff9;
      border-radius: 15px;
      width: 0;
      transition: width 200ms ease;
    }

    .sd-loader-content {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      .sd-loader-icon {
        margin-right: 12px;
        margin-left: 12px;

        i {
          font-size: 14px;
        }
      }

      .sd-loader-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.2px;
        color: #1c1c28;
      }
    }
  }

  .sd-upload-finish-icon {
    width: 30px;
    text-align: left;

    .sd-finish-btn {
      background: none;
      color: #ff3b3b;

      &:disabled {
        color: #8f90a6;
      }

      i {
        font-size: 20px;
      }
    }
  }
}
