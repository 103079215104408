.content-wrapper {
  max-width: $xl-screen;
  margin: 0 auto;
  padding: 0 70px;
  //transition: padding 0.3s ease-in-out;

  @media (max-width: $lg-screen) {
    padding: 0 16px;
  }
}

.content-wrapper-lg {
  max-width: $xxxl-screen;
  margin: 0 auto;
  padding: 0 70px;
  //transition: padding 0.3s ease-in-out;

  @media (max-width: $xxl-screen) {
    padding: 0 30px;
  }
}

.my-spacer {
  flex: 1;
}

.no-transition {
  transition: none !important;
}

.primary-text {
  color: $primary-color;
}

.secondary-text {
  color: $secondary-color;
}

.bold-text {
  font-weight: 700;
}

.slider-dots {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  .slider-dot {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    border: 1px solid #0ea5e9;
    margin: 0 4px;
    transition: all 0.3s ease-in-out;

    &.active {
      width: 14px;
      border-color: #7dd3fc;
      background-color: #7dd3fc;
    }
  }
}
