.album-info {
  .album-image {
    display: block;
    margin: 0 auto;
    width: 200px;
  }

  .album-lyrics-content {
    max-height: 200px;
    overflow-y: auto;
  }
}
