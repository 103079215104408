@import '../_typography';

.table-wrapper {
  overflow-x: auto;

  .table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;

    tr {
      border-bottom: 1px solid #f1f1f1;

      th {
        @extend .tg-body-sm;
        padding: 12px 24px;
        color: #4f5b67;
      }

      td {
        @extend .tg-body-md;
        padding: 14px 24px;
      }
    }

    tbody tr {
      transition: background-color 0.1s ease-in-out;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}
