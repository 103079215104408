.tg-display-lg {
  font-weight: 600;
  font-size: 72px;
  line-height: 120%;
}

.tg-display-md {
  font-weight: 600;
  font-size: 64px;
  line-height: 120%;
}

.tg-display-sm {
  font-weight: 600;
  font-size: 54px;
  line-height: 120%;
}

.tg-heading-1 {
  font-weight: 600;
  font-size: 42px;
  line-height: 120%;
}

.tg-heading-2 {
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
}

.tg-heading-3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
}

.tg-heading-4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.tg-heading-5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
}

.tg-heading-6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.tg-body-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
}

.tg-body-sm {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.tg-body-md {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.tg-body-lg {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
