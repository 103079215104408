/* using from https://github.com/toviszsolt/css-material-colors */

.bg-red {
  background-color: #f44336;
}

.bg-red-50 {
  background-color: #ffebee;
}

.bg-red-100 {
  background-color: #ffcdd2;
}

.bg-red-200 {
  background-color: #ef9a9a;
}

.bg-red-300 {
  background-color: #e57373;
}

.bg-red-400 {
  background-color: #ef5350;
}

.bg-red-500 {
  background-color: #f44336;
}

.bg-red-600 {
  background-color: #e53935;
}

.bg-red-700 {
  background-color: #d32f2f;
}

.bg-red-800 {
  background-color: #c62828;
}

.bg-red-900 {
  background-color: #b71c1c;
}

.bg-red-a100 {
  background-color: #ff8a80;
}

.bg-red-a200 {
  background-color: #ff5252;
}

.bg-red-a400 {
  background-color: #ff1744;
}

.bg-red-a700 {
  background-color: #d50000;
}

.bg-pink {
  background-color: #e91e63;
}

.bg-pink-50 {
  background-color: #fce4ec;
}

.bg-pink-100 {
  background-color: #f8bbd0;
}

.bg-pink-200 {
  background-color: #f48fb1;
}

.bg-pink-300 {
  background-color: #f06292;
}

.bg-pink-400 {
  background-color: #ec407a;
}

.bg-pink-500 {
  background-color: #e91e63;
}

.bg-pink-600 {
  background-color: #d81b60;
}

.bg-pink-700 {
  background-color: #c2185b;
}

.bg-pink-800 {
  background-color: #ad1457;
}

.bg-pink-900 {
  background-color: #880e4f;
}

.bg-pink-a100 {
  background-color: #ff80ab;
}

.bg-pink-a200 {
  background-color: #ff4081;
}

.bg-pink-a400 {
  background-color: #f50057;
}

.bg-pink-a700 {
  background-color: #c51162;
}

.bg-purple {
  background-color: #9c27b0;
}

.bg-purple-50 {
  background-color: #f3e5f5;
}

.bg-purple-100 {
  background-color: #e1bee7;
}

.bg-purple-200 {
  background-color: #ce93d8;
}

.bg-purple-300 {
  background-color: #ba68c8;
}

.bg-purple-400 {
  background-color: #ab47bc;
}

.bg-purple-500 {
  background-color: #9c27b0;
}

.bg-purple-600 {
  background-color: #8e24aa;
}

.bg-purple-700 {
  background-color: #7b1fa2;
}

.bg-purple-800 {
  background-color: #6a1b9a;
}

.bg-purple-900 {
  background-color: #4a148c;
}

.bg-purple-a100 {
  background-color: #ea80fc;
}

.bg-purple-a200 {
  background-color: #e040fb;
}

.bg-purple-a400 {
  background-color: #d500f9;
}

.bg-purple-a700 {
  background-color: #a0f;
}

.bg-deep-purple {
  background-color: #673ab7;
}

.bg-deep-purple-50 {
  background-color: #ede7f6;
}

.bg-deep-purple-100 {
  background-color: #d1c4e9;
}

.bg-deep-purple-200 {
  background-color: #b39ddb;
}

.bg-deep-purple-300 {
  background-color: #9575cd;
}

.bg-deep-purple-400 {
  background-color: #7e57c2;
}

.bg-deep-purple-500 {
  background-color: #673ab7;
}

.bg-deep-purple-600 {
  background-color: #5e35b1;
}

.bg-deep-purple-700 {
  background-color: #512da8;
}

.bg-deep-purple-800 {
  background-color: #4527a0;
}

.bg-deep-purple-900 {
  background-color: #311b92;
}

.bg-deep-purple-a100 {
  background-color: #b388ff;
}

.bg-deep-purple-a200 {
  background-color: #7c4dff;
}

.bg-deep-purple-a400 {
  background-color: #651fff;
}

.bg-deep-purple-a700 {
  background-color: #6200ea;
}

.bg-indigo {
  background-color: #3f51b5;
}

.bg-indigo-50 {
  background-color: #e8eaf6;
}

.bg-indigo-100 {
  background-color: #c5cae9;
}

.bg-indigo-200 {
  background-color: #9fa8da;
}

.bg-indigo-300 {
  background-color: #7986cb;
}

.bg-indigo-400 {
  background-color: #5c6bc0;
}

.bg-indigo-500 {
  background-color: #3f51b5;
}

.bg-indigo-600 {
  background-color: #3949ab;
}

.bg-indigo-700 {
  background-color: #303f9f;
}

.bg-indigo-800 {
  background-color: #283593;
}

.bg-indigo-900 {
  background-color: #1a237e;
}

.bg-indigo-a100 {
  background-color: #8c9eff;
}

.bg-indigo-a200 {
  background-color: #536dfe;
}

.bg-indigo-a400 {
  background-color: #3d5afe;
}

.bg-indigo-a700 {
  background-color: #304ffe;
}

.bg-blue {
  background-color: #2196f3;
}

.bg-blue-50 {
  background-color: #e3f2fd;
}

.bg-blue-100 {
  background-color: #bbdefb;
}

.bg-blue-200 {
  background-color: #90caf9;
}

.bg-blue-300 {
  background-color: #64b5f6;
}

.bg-blue-400 {
  background-color: #42a5f5;
}

.bg-blue-500 {
  background-color: #2196f3;
}

.bg-blue-600 {
  background-color: #1e88e5;
}

.bg-blue-700 {
  background-color: #1976d2;
}

.bg-blue-800 {
  background-color: #1565c0;
}

.bg-blue-900 {
  background-color: #0d47a1;
}

.bg-blue-a100 {
  background-color: #82b1ff;
}

.bg-blue-a200 {
  background-color: #448aff;
}

.bg-blue-a400 {
  background-color: #2979ff;
}

.bg-blue-a700 {
  background-color: #2962ff;
}

.bg-light-blue {
  background-color: #03a9f4;
}

.bg-light-blue-50 {
  background-color: #e1f5fe;
}

.bg-light-blue-100 {
  background-color: #b3e5fc;
}

.bg-light-blue-200 {
  background-color: #81d4fa;
}

.bg-light-blue-300 {
  background-color: #4fc3f7;
}

.bg-light-blue-400 {
  background-color: #29b6f6;
}

.bg-light-blue-500 {
  background-color: #03a9f4;
}

.bg-light-blue-600 {
  background-color: #039be5;
}

.bg-light-blue-700 {
  background-color: #0288d1;
}

.bg-light-blue-800 {
  background-color: #0277bd;
}

.bg-light-blue-900 {
  background-color: #01579b;
}

.bg-light-blue-a100 {
  background-color: #80d8ff;
}

.bg-light-blue-a200 {
  background-color: #40c4ff;
}

.bg-light-blue-a400 {
  background-color: #00b0ff;
}

.bg-light-blue-a700 {
  background-color: #0091ea;
}

.bg-cyan {
  background-color: #00bcd4;
}

.bg-cyan-50 {
  background-color: #e0f7fa;
}

.bg-cyan-100 {
  background-color: #b2ebf2;
}

.bg-cyan-200 {
  background-color: #80deea;
}

.bg-cyan-300 {
  background-color: #4dd0e1;
}

.bg-cyan-400 {
  background-color: #26c6da;
}

.bg-cyan-500 {
  background-color: #00bcd4;
}

.bg-cyan-600 {
  background-color: #00acc1;
}

.bg-cyan-700 {
  background-color: #0097a7;
}

.bg-cyan-800 {
  background-color: #00838f;
}

.bg-cyan-900 {
  background-color: #006064;
}

.bg-cyan-a100 {
  background-color: #84ffff;
}

.bg-cyan-a200 {
  background-color: #18ffff;
}

.bg-cyan-a400 {
  background-color: #00e5ff;
}

.bg-cyan-a700 {
  background-color: #00b8d4;
}

.bg-teal {
  background-color: #009688;
}

.bg-teal-50 {
  background-color: #e0f2f1;
}

.bg-teal-100 {
  background-color: #b2dfdb;
}

.bg-teal-200 {
  background-color: #80cbc4;
}

.bg-teal-300 {
  background-color: #4db6ac;
}

.bg-teal-400 {
  background-color: #26a69a;
}

.bg-teal-500 {
  background-color: #009688;
}

.bg-teal-600 {
  background-color: #00897b;
}

.bg-teal-700 {
  background-color: #00796b;
}

.bg-teal-800 {
  background-color: #00695c;
}

.bg-teal-900 {
  background-color: #004d40;
}

.bg-teal-a100 {
  background-color: #a7ffeb;
}

.bg-teal-a200 {
  background-color: #64ffda;
}

.bg-teal-a400 {
  background-color: #1de9b6;
}

.bg-teal-a700 {
  background-color: #00bfa5;
}

.bg-green {
  background-color: #4caf50;
}

.bg-green-50 {
  background-color: #e8f5e9;
}

.bg-green-100 {
  background-color: #c8e6c9;
}

.bg-green-200 {
  background-color: #a5d6a7;
}

.bg-green-300 {
  background-color: #81c784;
}

.bg-green-400 {
  background-color: #66bb6a;
}

.bg-green-500 {
  background-color: #4caf50;
}

.bg-green-600 {
  background-color: #43a047;
}

.bg-green-700 {
  background-color: #388e3c;
}

.bg-green-800 {
  background-color: #2e7d32;
}

.bg-green-900 {
  background-color: #1b5e20;
}

.bg-green-a100 {
  background-color: #b9f6ca;
}

.bg-green-a200 {
  background-color: #69f0ae;
}

.bg-green-a400 {
  background-color: #00e676;
}

.bg-green-a700 {
  background-color: #00c853;
}

.bg-light-green {
  background-color: #8bc34a;
}

.bg-light-green-50 {
  background-color: #f1f8e9;
}

.bg-light-green-100 {
  background-color: #dcedc8;
}

.bg-light-green-200 {
  background-color: #c5e1a5;
}

.bg-light-green-300 {
  background-color: #aed581;
}

.bg-light-green-400 {
  background-color: #9ccc65;
}

.bg-light-green-500 {
  background-color: #8bc34a;
}

.bg-light-green-600 {
  background-color: #7cb342;
}

.bg-light-green-700 {
  background-color: #689f38;
}

.bg-light-green-800 {
  background-color: #558b2f;
}

.bg-light-green-900 {
  background-color: #33691e;
}

.bg-light-green-a100 {
  background-color: #ccff90;
}

.bg-light-green-a200 {
  background-color: #b2ff59;
}

.bg-light-green-a400 {
  background-color: #76ff03;
}

.bg-light-green-a700 {
  background-color: #64dd17;
}

.bg-lime {
  background-color: #cddc39;
}

.bg-lime-50 {
  background-color: #f9fbe7;
}

.bg-lime-100 {
  background-color: #f0f4c3;
}

.bg-lime-200 {
  background-color: #e6ee9c;
}

.bg-lime-300 {
  background-color: #dce775;
}

.bg-lime-400 {
  background-color: #d4e157;
}

.bg-lime-500 {
  background-color: #cddc39;
}

.bg-lime-600 {
  background-color: #c0ca33;
}

.bg-lime-700 {
  background-color: #afb42b;
}

.bg-lime-800 {
  background-color: #9e9d24;
}

.bg-lime-900 {
  background-color: #827717;
}

.bg-lime-a100 {
  background-color: #f4ff81;
}

.bg-lime-a200 {
  background-color: #eeff41;
}

.bg-lime-a400 {
  background-color: #c6ff00;
}

.bg-lime-a700 {
  background-color: #aeea00;
}

.bg-yellow {
  background-color: #ffeb3b;
}

.bg-yellow-50 {
  background-color: #fffde7;
}

.bg-yellow-100 {
  background-color: #fff9c4;
}

.bg-yellow-200 {
  background-color: #fff59d;
}

.bg-yellow-300 {
  background-color: #fff176;
}

.bg-yellow-400 {
  background-color: #ffee58;
}

.bg-yellow-500 {
  background-color: #ffeb3b;
}

.bg-yellow-600 {
  background-color: #fdd835;
}

.bg-yellow-700 {
  background-color: #fbc02d;
}

.bg-yellow-800 {
  background-color: #f9a825;
}

.bg-yellow-900 {
  background-color: #f57f17;
}

.bg-yellow-a100 {
  background-color: #ffff8d;
}

.bg-yellow-a200 {
  background-color: #ff0;
}

.bg-yellow-a400 {
  background-color: #ffea00;
}

.bg-yellow-a700 {
  background-color: #ffd600;
}

.bg-amber {
  background-color: #ffc107;
}

.bg-amber-50 {
  background-color: #fff8e1;
}

.bg-amber-100 {
  background-color: #ffecb3;
}

.bg-amber-200 {
  background-color: #ffe082;
}

.bg-amber-300 {
  background-color: #ffd54f;
}

.bg-amber-400 {
  background-color: #ffca28;
}

.bg-amber-500 {
  background-color: #ffc107;
}

.bg-amber-600 {
  background-color: #ffb300;
}

.bg-amber-700 {
  background-color: #ffa000;
}

.bg-amber-800 {
  background-color: #ff8f00;
}

.bg-amber-900 {
  background-color: #ff6f00;
}

.bg-amber-a100 {
  background-color: #ffe57f;
}

.bg-amber-a200 {
  background-color: #ffd740;
}

.bg-amber-a400 {
  background-color: #ffc400;
}

.bg-amber-a700 {
  background-color: #ffab00;
}

.bg-orange {
  background-color: #ff9800;
}

.bg-orange-50 {
  background-color: #fff3e0;
}

.bg-orange-100 {
  background-color: #ffe0b2;
}

.bg-orange-200 {
  background-color: #ffcc80;
}

.bg-orange-300 {
  background-color: #ffb74d;
}

.bg-orange-400 {
  background-color: #ffa726;
}

.bg-orange-500 {
  background-color: #ff9800;
}

.bg-orange-600 {
  background-color: #fb8c00;
}

.bg-orange-700 {
  background-color: #f57c00;
}

.bg-orange-800 {
  background-color: #ef6c00;
}

.bg-orange-900 {
  background-color: #e65100;
}

.bg-orange-a100 {
  background-color: #ffd180;
}

.bg-orange-a200 {
  background-color: #ffab40;
}

.bg-orange-a400 {
  background-color: #ff9100;
}

.bg-orange-a700 {
  background-color: #ff6d00;
}

.bg-deep-orange {
  background-color: #ff5722;
}

.bg-deep-orange-50 {
  background-color: #fbe9e7;
}

.bg-deep-orange-100 {
  background-color: #ffccbc;
}

.bg-deep-orange-200 {
  background-color: #ffab91;
}

.bg-deep-orange-300 {
  background-color: #ff8a65;
}

.bg-deep-orange-400 {
  background-color: #ff7043;
}

.bg-deep-orange-500 {
  background-color: #ff5722;
}

.bg-deep-orange-600 {
  background-color: #f4511e;
}

.bg-deep-orange-700 {
  background-color: #e64a19;
}

.bg-deep-orange-800 {
  background-color: #d84315;
}

.bg-deep-orange-900 {
  background-color: #bf360c;
}

.bg-deep-orange-a100 {
  background-color: #ff9e80;
}

.bg-deep-orange-a200 {
  background-color: #ff6e40;
}

.bg-deep-orange-a400 {
  background-color: #ff3d00;
}

.bg-deep-orange-a700 {
  background-color: #dd2c00;
}

.bg-brown {
  background-color: #795548;
}

.bg-brown-50 {
  background-color: #efebe9;
}

.bg-brown-100 {
  background-color: #d7ccc8;
}

.bg-brown-200 {
  background-color: #bcaaa4;
}

.bg-brown-300 {
  background-color: #a1887f;
}

.bg-brown-400 {
  background-color: #8d6e63;
}

.bg-brown-500 {
  background-color: #795548;
}

.bg-brown-600 {
  background-color: #6d4c41;
}

.bg-brown-700 {
  background-color: #5d4037;
}

.bg-brown-800 {
  background-color: #4e342e;
}

.bg-brown-900 {
  background-color: #3e2723;
}

.bg-grey {
  background-color: #9e9e9e;
}

.bg-grey-50 {
  background-color: #fafafa;
}

.bg-grey-100 {
  background-color: #f5f5f5;
}

.bg-grey-200 {
  background-color: #eee;
}

.bg-grey-300 {
  background-color: #e0e0e0;
}

.bg-grey-400 {
  background-color: #bdbdbd;
}

.bg-grey-500 {
  background-color: #9e9e9e;
}

.bg-grey-600 {
  background-color: #757575;
}

.bg-grey-700 {
  background-color: #616161;
}

.bg-grey-800 {
  background-color: #424242;
}

.bg-grey-900 {
  background-color: #212121;
}

.bg-blue-grey {
  background-color: #607d8b;
}

.bg-blue-grey-50 {
  background-color: #eceff1;
}

.bg-blue-grey-100 {
  background-color: #cfd8dc;
}

.bg-blue-grey-200 {
  background-color: #b0bec5;
}

.bg-blue-grey-300 {
  background-color: #90a4ae;
}

.bg-blue-grey-400 {
  background-color: #78909c;
}

.bg-blue-grey-500 {
  background-color: #607d8b;
}

.bg-blue-grey-600 {
  background-color: #546e7a;
}

.bg-blue-grey-700 {
  background-color: #455a64;
}

.bg-blue-grey-800 {
  background-color: #37474f;
}

.bg-blue-grey-900 {
  background-color: #263238;
}

.bg-black {
  background-color: #000;
}

.bg-white {
  background-color: #fff;
}

.fg-red {
  color: #f44336;
}

.fg-red-50 {
  color: #ffebee;
}

.fg-red-100 {
  color: #ffcdd2;
}

.fg-red-200 {
  color: #ef9a9a;
}

.fg-red-300 {
  color: #e57373;
}

.fg-red-400 {
  color: #ef5350;
}

.fg-red-500 {
  color: #f44336;
}

.fg-red-600 {
  color: #e53935;
}

.fg-red-700 {
  color: #d32f2f;
}

.fg-red-800 {
  color: #c62828;
}

.fg-red-900 {
  color: #b71c1c;
}

.fg-red-a100 {
  color: #ff8a80;
}

.fg-red-a200 {
  color: #ff5252;
}

.fg-red-a400 {
  color: #ff1744;
}

.fg-red-a700 {
  color: #d50000;
}

.fg-pink {
  color: #e91e63;
}

.fg-pink-50 {
  color: #fce4ec;
}

.fg-pink-100 {
  color: #f8bbd0;
}

.fg-pink-200 {
  color: #f48fb1;
}

.fg-pink-300 {
  color: #f06292;
}

.fg-pink-400 {
  color: #ec407a;
}

.fg-pink-500 {
  color: #e91e63;
}

.fg-pink-600 {
  color: #d81b60;
}

.fg-pink-700 {
  color: #c2185b;
}

.fg-pink-800 {
  color: #ad1457;
}

.fg-pink-900 {
  color: #880e4f;
}

.fg-pink-a100 {
  color: #ff80ab;
}

.fg-pink-a200 {
  color: #ff4081;
}

.fg-pink-a400 {
  color: #f50057;
}

.fg-pink-a700 {
  color: #c51162;
}

.fg-purple {
  color: #9c27b0;
}

.fg-purple-50 {
  color: #f3e5f5;
}

.fg-purple-100 {
  color: #e1bee7;
}

.fg-purple-200 {
  color: #ce93d8;
}

.fg-purple-300 {
  color: #ba68c8;
}

.fg-purple-400 {
  color: #ab47bc;
}

.fg-purple-500 {
  color: #9c27b0;
}

.fg-purple-600 {
  color: #8e24aa;
}

.fg-purple-700 {
  color: #7b1fa2;
}

.fg-purple-800 {
  color: #6a1b9a;
}

.fg-purple-900 {
  color: #4a148c;
}

.fg-purple-a100 {
  color: #ea80fc;
}

.fg-purple-a200 {
  color: #e040fb;
}

.fg-purple-a400 {
  color: #d500f9;
}

.fg-purple-a700 {
  color: #a0f;
}

.fg-deep-purple {
  color: #673ab7;
}

.fg-deep-purple-50 {
  color: #ede7f6;
}

.fg-deep-purple-100 {
  color: #d1c4e9;
}

.fg-deep-purple-200 {
  color: #b39ddb;
}

.fg-deep-purple-300 {
  color: #9575cd;
}

.fg-deep-purple-400 {
  color: #7e57c2;
}

.fg-deep-purple-500 {
  color: #673ab7;
}

.fg-deep-purple-600 {
  color: #5e35b1;
}

.fg-deep-purple-700 {
  color: #512da8;
}

.fg-deep-purple-800 {
  color: #4527a0;
}

.fg-deep-purple-900 {
  color: #311b92;
}

.fg-deep-purple-a100 {
  color: #b388ff;
}

.fg-deep-purple-a200 {
  color: #7c4dff;
}

.fg-deep-purple-a400 {
  color: #651fff;
}

.fg-deep-purple-a700 {
  color: #6200ea;
}

.fg-indigo {
  color: #3f51b5;
}

.fg-indigo-50 {
  color: #e8eaf6;
}

.fg-indigo-100 {
  color: #c5cae9;
}

.fg-indigo-200 {
  color: #9fa8da;
}

.fg-indigo-300 {
  color: #7986cb;
}

.fg-indigo-400 {
  color: #5c6bc0;
}

.fg-indigo-500 {
  color: #3f51b5;
}

.fg-indigo-600 {
  color: #3949ab;
}

.fg-indigo-700 {
  color: #303f9f;
}

.fg-indigo-800 {
  color: #283593;
}

.fg-indigo-900 {
  color: #1a237e;
}

.fg-indigo-a100 {
  color: #8c9eff;
}

.fg-indigo-a200 {
  color: #536dfe;
}

.fg-indigo-a400 {
  color: #3d5afe;
}

.fg-indigo-a700 {
  color: #304ffe;
}

.fg-blue {
  color: #2196f3;
}

.fg-blue-50 {
  color: #e3f2fd;
}

.fg-blue-100 {
  color: #bbdefb;
}

.fg-blue-200 {
  color: #90caf9;
}

.fg-blue-300 {
  color: #64b5f6;
}

.fg-blue-400 {
  color: #42a5f5;
}

.fg-blue-500 {
  color: #2196f3;
}

.fg-blue-600 {
  color: #1e88e5;
}

.fg-blue-700 {
  color: #1976d2;
}

.fg-blue-800 {
  color: #1565c0;
}

.fg-blue-900 {
  color: #0d47a1;
}

.fg-blue-a100 {
  color: #82b1ff;
}

.fg-blue-a200 {
  color: #448aff;
}

.fg-blue-a400 {
  color: #2979ff;
}

.fg-blue-a700 {
  color: #2962ff;
}

.fg-light-blue {
  color: #03a9f4;
}

.fg-light-blue-50 {
  color: #e1f5fe;
}

.fg-light-blue-100 {
  color: #b3e5fc;
}

.fg-light-blue-200 {
  color: #81d4fa;
}

.fg-light-blue-300 {
  color: #4fc3f7;
}

.fg-light-blue-400 {
  color: #29b6f6;
}

.fg-light-blue-500 {
  color: #03a9f4;
}

.fg-light-blue-600 {
  color: #039be5;
}

.fg-light-blue-700 {
  color: #0288d1;
}

.fg-light-blue-800 {
  color: #0277bd;
}

.fg-light-blue-900 {
  color: #01579b;
}

.fg-light-blue-a100 {
  color: #80d8ff;
}

.fg-light-blue-a200 {
  color: #40c4ff;
}

.fg-light-blue-a400 {
  color: #00b0ff;
}

.fg-light-blue-a700 {
  color: #0091ea;
}

.fg-cyan {
  color: #00bcd4;
}

.fg-cyan-50 {
  color: #e0f7fa;
}

.fg-cyan-100 {
  color: #b2ebf2;
}

.fg-cyan-200 {
  color: #80deea;
}

.fg-cyan-300 {
  color: #4dd0e1;
}

.fg-cyan-400 {
  color: #26c6da;
}

.fg-cyan-500 {
  color: #00bcd4;
}

.fg-cyan-600 {
  color: #00acc1;
}

.fg-cyan-700 {
  color: #0097a7;
}

.fg-cyan-800 {
  color: #00838f;
}

.fg-cyan-900 {
  color: #006064;
}

.fg-cyan-a100 {
  color: #84ffff;
}

.fg-cyan-a200 {
  color: #18ffff;
}

.fg-cyan-a400 {
  color: #00e5ff;
}

.fg-cyan-a700 {
  color: #00b8d4;
}

.fg-teal {
  color: #009688;
}

.fg-teal-50 {
  color: #e0f2f1;
}

.fg-teal-100 {
  color: #b2dfdb;
}

.fg-teal-200 {
  color: #80cbc4;
}

.fg-teal-300 {
  color: #4db6ac;
}

.fg-teal-400 {
  color: #26a69a;
}

.fg-teal-500 {
  color: #009688;
}

.fg-teal-600 {
  color: #00897b;
}

.fg-teal-700 {
  color: #00796b;
}

.fg-teal-800 {
  color: #00695c;
}

.fg-teal-900 {
  color: #004d40;
}

.fg-teal-a100 {
  color: #a7ffeb;
}

.fg-teal-a200 {
  color: #64ffda;
}

.fg-teal-a400 {
  color: #1de9b6;
}

.fg-teal-a700 {
  color: #00bfa5;
}

.fg-green {
  color: #4caf50;
}

.fg-green-50 {
  color: #e8f5e9;
}

.fg-green-100 {
  color: #c8e6c9;
}

.fg-green-200 {
  color: #a5d6a7;
}

.fg-green-300 {
  color: #81c784;
}

.fg-green-400 {
  color: #66bb6a;
}

.fg-green-500 {
  color: #4caf50;
}

.fg-green-600 {
  color: #43a047;
}

.fg-green-700 {
  color: #388e3c;
}

.fg-green-800 {
  color: #2e7d32;
}

.fg-green-900 {
  color: #1b5e20;
}

.fg-green-a100 {
  color: #b9f6ca;
}

.fg-green-a200 {
  color: #69f0ae;
}

.fg-green-a400 {
  color: #00e676;
}

.fg-green-a700 {
  color: #00c853;
}

.fg-light-green {
  color: #8bc34a;
}

.fg-light-green-50 {
  color: #f1f8e9;
}

.fg-light-green-100 {
  color: #dcedc8;
}

.fg-light-green-200 {
  color: #c5e1a5;
}

.fg-light-green-300 {
  color: #aed581;
}

.fg-light-green-400 {
  color: #9ccc65;
}

.fg-light-green-500 {
  color: #8bc34a;
}

.fg-light-green-600 {
  color: #7cb342;
}

.fg-light-green-700 {
  color: #689f38;
}

.fg-light-green-800 {
  color: #558b2f;
}

.fg-light-green-900 {
  color: #33691e;
}

.fg-light-green-a100 {
  color: #ccff90;
}

.fg-light-green-a200 {
  color: #b2ff59;
}

.fg-light-green-a400 {
  color: #76ff03;
}

.fg-light-green-a700 {
  color: #64dd17;
}

.fg-lime {
  color: #cddc39;
}

.fg-lime-50 {
  color: #f9fbe7;
}

.fg-lime-100 {
  color: #f0f4c3;
}

.fg-lime-200 {
  color: #e6ee9c;
}

.fg-lime-300 {
  color: #dce775;
}

.fg-lime-400 {
  color: #d4e157;
}

.fg-lime-500 {
  color: #cddc39;
}

.fg-lime-600 {
  color: #c0ca33;
}

.fg-lime-700 {
  color: #afb42b;
}

.fg-lime-800 {
  color: #9e9d24;
}

.fg-lime-900 {
  color: #827717;
}

.fg-lime-a100 {
  color: #f4ff81;
}

.fg-lime-a200 {
  color: #eeff41;
}

.fg-lime-a400 {
  color: #c6ff00;
}

.fg-lime-a700 {
  color: #aeea00;
}

.fg-yellow {
  color: #ffeb3b;
}

.fg-yellow-50 {
  color: #fffde7;
}

.fg-yellow-100 {
  color: #fff9c4;
}

.fg-yellow-200 {
  color: #fff59d;
}

.fg-yellow-300 {
  color: #fff176;
}

.fg-yellow-400 {
  color: #ffee58;
}

.fg-yellow-500 {
  color: #ffeb3b;
}

.fg-yellow-600 {
  color: #fdd835;
}

.fg-yellow-700 {
  color: #fbc02d;
}

.fg-yellow-800 {
  color: #f9a825;
}

.fg-yellow-900 {
  color: #f57f17;
}

.fg-yellow-a100 {
  color: #ffff8d;
}

.fg-yellow-a200 {
  color: #ff0;
}

.fg-yellow-a400 {
  color: #ffea00;
}

.fg-yellow-a700 {
  color: #ffd600;
}

.fg-amber {
  color: #ffc107;
}

.fg-amber-50 {
  color: #fff8e1;
}

.fg-amber-100 {
  color: #ffecb3;
}

.fg-amber-200 {
  color: #ffe082;
}

.fg-amber-300 {
  color: #ffd54f;
}

.fg-amber-400 {
  color: #ffca28;
}

.fg-amber-500 {
  color: #ffc107;
}

.fg-amber-600 {
  color: #ffb300;
}

.fg-amber-700 {
  color: #ffa000;
}

.fg-amber-800 {
  color: #ff8f00;
}

.fg-amber-900 {
  color: #ff6f00;
}

.fg-amber-a100 {
  color: #ffe57f;
}

.fg-amber-a200 {
  color: #ffd740;
}

.fg-amber-a400 {
  color: #ffc400;
}

.fg-amber-a700 {
  color: #ffab00;
}

.fg-orange {
  color: #ff9800;
}

.fg-orange-50 {
  color: #fff3e0;
}

.fg-orange-100 {
  color: #ffe0b2;
}

.fg-orange-200 {
  color: #ffcc80;
}

.fg-orange-300 {
  color: #ffb74d;
}

.fg-orange-400 {
  color: #ffa726;
}

.fg-orange-500 {
  color: #ff9800;
}

.fg-orange-600 {
  color: #fb8c00;
}

.fg-orange-700 {
  color: #f57c00;
}

.fg-orange-800 {
  color: #ef6c00;
}

.fg-orange-900 {
  color: #e65100;
}

.fg-orange-a100 {
  color: #ffd180;
}

.fg-orange-a200 {
  color: #ffab40;
}

.fg-orange-a400 {
  color: #ff9100;
}

.fg-orange-a700 {
  color: #ff6d00;
}

.fg-deep-orange {
  color: #ff5722;
}

.fg-deep-orange-50 {
  color: #fbe9e7;
}

.fg-deep-orange-100 {
  color: #ffccbc;
}

.fg-deep-orange-200 {
  color: #ffab91;
}

.fg-deep-orange-300 {
  color: #ff8a65;
}

.fg-deep-orange-400 {
  color: #ff7043;
}

.fg-deep-orange-500 {
  color: #ff5722;
}

.fg-deep-orange-600 {
  color: #f4511e;
}

.fg-deep-orange-700 {
  color: #e64a19;
}

.fg-deep-orange-800 {
  color: #d84315;
}

.fg-deep-orange-900 {
  color: #bf360c;
}

.fg-deep-orange-a100 {
  color: #ff9e80;
}

.fg-deep-orange-a200 {
  color: #ff6e40;
}

.fg-deep-orange-a400 {
  color: #ff3d00;
}

.fg-deep-orange-a700 {
  color: #dd2c00;
}

.fg-brown {
  color: #795548;
}

.fg-brown-50 {
  color: #efebe9;
}

.fg-brown-100 {
  color: #d7ccc8;
}

.fg-brown-200 {
  color: #bcaaa4;
}

.fg-brown-300 {
  color: #a1887f;
}

.fg-brown-400 {
  color: #8d6e63;
}

.fg-brown-500 {
  color: #795548;
}

.fg-brown-600 {
  color: #6d4c41;
}

.fg-brown-700 {
  color: #5d4037;
}

.fg-brown-800 {
  color: #4e342e;
}

.fg-brown-900 {
  color: #3e2723;
}

.fg-grey {
  color: #9e9e9e;
}

.fg-grey-50 {
  color: #fafafa;
}

.fg-grey-100 {
  color: #f5f5f5;
}

.fg-grey-200 {
  color: #eee;
}

.fg-grey-300 {
  color: #e0e0e0;
}

.fg-grey-400 {
  color: #bdbdbd;
}

.fg-grey-500 {
  color: #9e9e9e;
}

.fg-grey-600 {
  color: #757575;
}

.fg-grey-700 {
  color: #616161;
}

.fg-grey-800 {
  color: #424242;
}

.fg-grey-900 {
  color: #212121;
}

.fg-blue-grey {
  color: #607d8b;
}

.fg-blue-grey-50 {
  color: #eceff1;
}

.fg-blue-grey-100 {
  color: #cfd8dc;
}

.fg-blue-grey-200 {
  color: #b0bec5;
}

.fg-blue-grey-300 {
  color: #90a4ae;
}

.fg-blue-grey-400 {
  color: #78909c;
}

.fg-blue-grey-500 {
  color: #607d8b;
}

.fg-blue-grey-600 {
  color: #546e7a;
}

.fg-blue-grey-700 {
  color: #455a64;
}

.fg-blue-grey-800 {
  color: #37474f;
}

.fg-blue-grey-900 {
  color: #263238;
}

.fg-black {
  color: #000;
}

.fg-white {
  color: #fff;
}
