/* global styles are here */

.small-btn {
  padding: 6px 10px;
  font-size: 12px;
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  border-color: #007bff;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  border-color: #28a745;
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  border-color: #dc3545;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  border-color: #ffc107;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  border-color: #17a2b8;
}

/* general list */
.general-list {
  .general-list-head {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    .head-start {
    }

    .head-end {
      margin-right: auto;
    }
  }

  .general-list-footer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
}
