.avatar-stack {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .avatar-stack-item {
    margin-right: -20px;

    &:first-child {
      margin-right: 0;
    }
  }
}
