@import '../typography';

.avatar-with-name {
  display: flex;
  align-items: center;
  justify-content: center;

  .avatar-name {
    @extend .tg-body-md;

    margin-right: 10px;
    flex: 1;
  }
}
