/* Fonts */
@import '../assets/fonts/YekanBakh/load.css';

/* Assets */
@import '../assets/styles/iconsax/iconsax.css';
@import '../assets/styles/fontawesome/css/all.css';

/* Essential Styles */
@import './reboot';
@import './variables';
@import './bootstrap-grid';
@import './media-queries';
@import './colors';
@import './general';

/* Global Component Styles
 * You can import your component styles here. These styles will be added globally and affect all pages.
 */

/* Global Page Styles
 * You can import your page-specific styles here. These styles will be added globally and affect all pages.
 */

/* Global Css Variables */
:root {
  --primary-color: #{$primary-color};
  --primary-color-action: #{darken($primary-color, 5%)};
  --secondary-color: #{$secondary-color};
  --secondary-color-action: #{darken($secondary-color, 5%)};
}

/* Global Styles */
html,
body,
#root {
  height: 100%;
}

body {
  font-family: $body-font-family;
}
