@import '../_typography';
@import '../_variables';

.btn {
  @extend .tg-body-md;

  cursor: pointer;
  padding: 13px 20px;
  border-radius: 32px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;
  position: relative;

  &.full-width {
    width: 100%;
  }

  .btn-icon {
    margin-left: 12px;
    font-size: 14px;
  }

  .btn-content {
    display: inherit;

    &.btn-loading {
      visibility: hidden;
    }
  }

  .btn-loader {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin btn-hover {
  &:hover {
    @content;
  }
}

@mixin btn-disabled {
  &.disabled,
  &:disabled {
    cursor: default;
    @content;
  }
}

.btn-primary {
  background: $primary-color;
  color: #fff;

  @include btn-hover {
    background: darken($primary-color, 5%);
  }

  @include btn-disabled {
    background: #d8d8fe;
  }
}

.btn-secondary {
  background: #fafafa;
  color: #0c1116;

  @include btn-hover {
    background: #f1f1f1;
  }

  @include btn-disabled {
    background: #fafafa;
    color: #d6dade;
  }
}

.btn-outline {
  background: transparent;
  border: 1px solid #eaecee;
  color: #0c1116;

  @include btn-hover {
    background: #fafafa;
  }

  @include btn-disabled {
    background: #fafafa;
    border-color: #fafafa;
    color: #d6dade;
  }
}
