@import '../_typography';
@import '../_media-queries';

.panel-wrapper {
  height: 100%;

  .panel-loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .panel {
    height: 100%;
    display: flex;

    .panel-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;

      .panel-container-header {
        padding: 22px 36px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f1f1f1;

        .panel-container-header-left {
          display: flex;
          align-items: center;

          .sidebar-toggle-btn {
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            margin-left: 26px;
            font-size: 22px;
          }
        }

        .panel-container-header-right {
          .profile-popover {
            position: relative;

            .profile-popover-toggle-btn {
              background: none;
              border: none;
              outline: none;
              cursor: pointer;
            }

            .profile-popover-content {
              position: absolute;
              top: 100%;
              left: 0;
              margin-top: 10px;
              background: #fff;
              border: 1px solid #eee;
              padding: 15px;
              border-radius: 5px;
              width: 250px;
              height: 250px;
              z-index: 2;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .profile-popover-name {
                margin-top: 24px;
                font-size: 18px;
                font-weight: 500;
              }

              .profile-popover-logout-btn {
                margin-top: 24px;
                border-color: #f44336;
                color: #f44336;
              }
            }
          }
        }

        .panel-container-header-title {
          @extend .tg-heading-5;
        }
      }

      .panel-content-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0; // prevent from overflowing

        .panel-bar-wrapper {
          padding: 22px 36px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f1f1f1;
        }

        .panel-content-container {
          background: #fafafa;
          flex: 1;
          overflow-y: auto;
          padding: 36px;

          @include only-phone-md {
            padding: 18px;
          }

          .panel-content {
            background: #ffffff;
            padding: 22px;
            border-radius: 5px;
          }
        }
      }
    }
  }
}
