@import '../../variables';
@import '../../media-queries';

.sd-auth-code {
  .sd-auth-code-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #8f90a6;
    margin-bottom: 10px;
  }

  .sd-auth-code-input {
    display: flex;
    align-items: center;

    input[type='tel'] {
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #f4f6f3;
      border-radius: 6px;
      border: 1px solid #0063f700;
      text-align: center;
      transition: all 200ms ease;
      margin-left: 24px;

      @include only-phone-lg {
        margin-left: 12px;
      }

      &:last-child {
        margin-left: 0;
      }

      &:focus {
        border-color: #0063f7;
        background-color: #f3f7fd;
      }
    }
  }
}
