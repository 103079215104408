@import '../_typography';

.icon-text {
  display: flex;
  align-items: center;

  .icon {
    font-size: 16px;
  }

  .text {
    @extend .tg-body-sm;
    margin-right: 8px;
  }
}

.icon-texts {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: -8px;

  li {
    padding: 8px;

    &:last-child {
      margin-left: 0;
    }
  }
}
