.sd-input-wrapper {
  .label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #8f90a6;
  }

  .sd-select {
    background: #f4f6f3;
    box-sizing: border-box;
    border-radius: 6px;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.2px;
    color: #8f90a6;
    padding: 18px 20px;
    padding-left: 40px;
    position: relative;
    cursor: pointer;

    .selected {
      color: #1c1c28;
    }

    .sd-select-icon {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #1c1c28;
      transition: all 300ms ease;
    }

    .sd-select-options {
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      margin-top: 15px;
      background-color: #fff;
      box-shadow: 0 1px 2px #ccc;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 6px;
      overflow: hidden;
      overflow-y: auto;
      max-height: 250px;
      display: none;
      z-index: 5;

      li {
        background-color: #fff;
        border-bottom: 1px solid #c7c9d9;
        padding: 15px 20px;
        color: #1c1c28;
        cursor: pointer;
        transition: all 100ms ease;
        position: relative;

        i {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover {
          background-color: #c7c9d9;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &.open {
      .sd-select-options {
        display: block;
        z-index: 5;
      }

      .sd-select-icon {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
  }
}
