$prefix: 'sd';

/* colors */
$primary-color: #0ea5e9;
$secondary-color: #ff7a00;
$success-color: #28a745;
$info-color: #17a2b8;
$warning-color: #ffc107;
$error-color: #dc3545;
$dark-color: #343a40;
$light-color: #f8f9fa;

/* Breakpoints */
$xsm-screen: 376px;
$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1140px;
$xxl-screen: 1340px;
$xxxl-screen: 1440px;

/* Typography */
$body-font-family: 'Yekan Bakh', sans-serif;
$heading-font-family: 'Yekan Bakh', sans-serif;
